<template>
    <div class="text-center">
        <v-dialog v-model="dialog" width="500" persistent>
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="success lighten-2" dark v-bind="attrs" v-on="on" style="width: 100%" x-large>
                    <v-icon dark large left>monetization_on</v-icon> Adicionar pagamento
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="headline primary white--text">{{ $t("Pagamento") }}</v-card-title>

                <v-card-text style="height: 200px">
                    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
                        <v-layout wrap ma-2 py-8>
                            <v-flex xs12>
                                <v-select v-model="method" :rules="[rules.required]" :items="methods" item-value="id" item-text="name" label="Método de pagamento"></v-select>
                            </v-flex>

                            <v-flex xs12>
                                <v-text-field
                                    v-model="value"
                                    :rules="[rules.required, rules.money, maxValue]"
                                    :label="$t('Valor')"
                                    v-mask="['#.##', '##.##', '###.##', '####.##', '#####.##']"
                                    return-masked-value
                                    prefix="R$"
                                    type="number"
                                    required
                                />
                            </v-flex>
                        </v-layout>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-layout wrap>
                        <v-flex xs6>
                            <v-btn color="success" @click="validate" style="width: 100%">
                                <v-icon dark left>check_circle</v-icon>
                                {{ $t("Finalizar") }}
                            </v-btn>
                        </v-flex>
                        <v-flex xs6>
                            <v-btn color="error" @click="onClose" style="width: 100%" :disabled="$store.getters.isLoading" :loading="$store.getters.isLoading">
                                <v-icon dark left>cancel</v-icon>
                                {{ $t("Cancelar") }}
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

import methods from "@/assets/json/methods.json";

export default {
    directives: { mask },

    props: {
        id: {
            type: Number,
            required: true,
        },
        max: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            dialog: false,
            methods,
            maxValue: (value) => !value || Number(value) <= Number(this.max) || `Valor máximo R$ ${this.max}`,
            value: null,
            method: null,
        };
    },

    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.$http
                    .post(`payments`, { value: this.value, method: this.method, service: this.id })
                    .then((result) => {
                        this.onClose();
                        this.$emit("update");
                        this.$eventHub.$emit("msgSuccess", result.message ? result.message : this.$t("Pagamento inserido com sucesso."));
                    })
                    .catch((error) => {
                        this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao inserir pagamento."));
                    });
            }
        },
        onClose() {
            this.dialog = false;
            this.value = null;
            this.$refs.form.reset();
            this.method = null;
        },
    },
};
</script>